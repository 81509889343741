import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
// Import all images
import editorial1 from '../Images/editorial.jpeg';
import editorial2 from '../Images/editorial2.jpeg';
import editorial3 from '../Images/editorial3.jpeg';
import editorial4 from '../Images/editorial4.jpeg';
import editorial5 from '../Images/editorial5.jpeg';
import editorial6 from '../Images/editorial6.jpeg';
import editorial7 from '../Images/editorial7.jpeg';
import editorial8 from '../Images/editorial8.jpeg';
import editorial9 from '../Images/editorial9.jpeg';
import editorial10 from '../Images/editorial10.jpeg';
import events1 from '../Images/events1.jpeg';
import events2 from '../Images/events2.jpeg';
import events3 from '../Images/events3.jpeg';
import events4 from '../Images/events4.jpeg';
import events5 from '../Images/events5.jpeg';
import events6 from '../Images/events6.jpeg';
import events7 from '../Images/events7.jpeg';
import events8 from '../Images/events8.jpeg';
import events9 from '../Images/events9.jpeg';
import musicVideo1 from '../Images/musicvideo.png';
import musicVideo2 from '../Images/musicvideo2.jpeg';
import musicVideo5 from '../Images/musicvideo5.jpeg';
import musicVideo6 from '../Images/musicvideo6.jpeg';
import musicVideo9 from '../Images/musicvideo9.jpeg';
import musicVideo10 from '../Images/musicvideo10.jpeg';
import musicVideo11 from '../Images/musicvideo11.jpeg';
import musicVideo12 from '../Images/musicvideo12.jpeg';
import musicVideo13 from '../Images/musicvideo13.jpeg';
import musicVideo14 from '../Images/musicvideo14.jpeg';
import musicVideo15 from '../Images/musicvideo15.jpeg';
import musicVideo16 from '../Images/musicvideo16.jpeg';
import musicVideo17 from '../Images/musicVideo17.jpeg'; 
import musicVideo18 from '../Images/musicVideo18.jpeg'; 
import musicVideo19 from '../Images/musicVideo19.jpeg'; 
import musicVideo20 from '../Images/musicVideo20.jpeg'; 
import musicVideo21 from '../Images/musicVideo21.jpeg';



const PortfolioWrapper = styled(motion.div)`
  max-width: 1600px;
  margin: 0 auto;
  padding: 6rem 2rem;
  background-color: #fff;
  color: #333;

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const SectionTitle = styled(motion.h2)`
  font-size: 3.5rem;
  text-align: center;
  margin-bottom: 4rem;
  font-weight: 300;
  letter-spacing: 0.05em;
  color: #333;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
`;

const ProjectRow = styled(motion.div)`
  display: flex;
  margin-bottom: 6rem;
  gap: 2rem;

  @media (max-width: 768px){
    flex-direction: column;
  }
`;

const ProjectColumn = styled(motion.div)`
  flex: 1;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  
`;

const ProjectImage = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
`;
const ArrDeeProjectImage = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  background-color: #f0f0f0;
`;


const ProjectOverlay = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background: linear-gradient(to top, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0) 100%);
  color: #333;

  @media (max-width: 768px) {
    background: linear-gradient(to top, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.009) 100%);
    padding: 1rem;
  }
`;

const ProjectType = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  color: #ff4081;
`;

const ProjectTitle = styled.h4`
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 0.5rem;
`;

const ProjectDescription = styled(motion.p)`
  font-size: 1rem;
  margin-top: 1rem;
  opacity: 0;
  @media (max-width: 768px) {
    opacity: 1;
  }
`;

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled(motion.div)`
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    max-width: 95%;
    max-height: 95%;
    padding: 1.5rem;
  }
`;

const ModalImageContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  box-sizing: border-box;
`;

const ModalImage = styled(motion.img)`
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
`;

const ArrowButton = styled(motion.button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  color: #333;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;

  @media (max-width: 768px) {
    padding: 0.3rem;
  }
`;

const LeftArrow = styled(ArrowButton)`
  left: 1rem;
`;

const RightArrow = styled(ArrowButton)`
  right: 1rem;
`;

const ThumbnailContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;


const Thumbnail = styled(motion.img)`
  width: 80px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;
  opacity: ${props => props.isSelected ? 1 : 0.6};
  transition: all 0.3s ease;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    opacity: 1;
  }

  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;

const CloseButton = styled(motion.button)`
  position: absolute;
  top: 0rem;
  right: 0rem;
  background: rgba(0, 0, 0, 0.1);
  border: none;
  color: #333;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 20;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    top: 5px;
    right: 5px;
    padding: 0.3rem;
  }
`;

const collections = [
  {
    type: "Editorial",
    title: "Deeds Magazine Digital: Rayvanny",
    description: "Lead stylist for East African music star Rayvanny Editorial",
    mainImage: editorial1,
    images: [editorial1, editorial2, editorial3, editorial4, editorial5, editorial6, editorial7, editorial8, editorial9, editorial10]
  },
  {
    type: "Editorial",
    title: "Deeds Magazine: Omah Lay",
    description: "Assistant styled Omah Lay for the Culture Unwrapped Issue, December/January 2023 | Lead Stylist: Leona Jay, Assistant Stylist: Rubyxly ",
    
    mainImage: events6,
    images: [events6, events7, events8, events9, musicVideo2, musicVideo5, musicVideo6]
  },
  {
    type: "Event",
    title: "Channel 4 'Love & Toxic: Blue Therapy'",
    description: "Styled presenter & Influencer Jourds",
    mainImage: events1,
    images: [events1, events2]
  },
  {
    type: "Event",
    title: "2024 Mobos Awards",
    description: "Styled Potter Payper's producer GX (Groundworks)",
    mainImage: events3,
    images: [events3, events4, events5]
  },
  {
    type: "Video",
    title: "FARDA - 'Soft Life'",
    description: "Styled Artist FARDA for Soft Life music video",
    mainImage: musicVideo1,
    images: [musicVideo1]
  },
  {
    type: "Video",
    title: "Phyno & ArrDee - Time Of My Life",
    description: "Assistant Styled ArrDee | Lead stylist: J_kogzy",
    mainImage: musicVideo17,
    images: [musicVideo17, musicVideo18, musicVideo19, musicVideo20, musicVideo21],
    imageStyle: {
      objectFit: 'contain',
      objectPosition: 'center',
      backgroundColor: '#f0f0f0',
    },
  },

  {
    type: "Video",
    title: "Summer Banton - 'BOYFRIEND'",
    description: "Styled Summer Banton and dancers for DANCE VIDEO SHOOT",
    mainImage: musicVideo9,
    images: [musicVideo9, musicVideo10, musicVideo11, musicVideo12, musicVideo13, musicVideo14, musicVideo15, musicVideo16]
  }, 


];

function Portfolio() {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentCollection, setCurrentCollection] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  

  const openModal = (collection) => {
    setCurrentCollection(collection);
    setCurrentImageIndex(0);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => 
      (prevIndex + 1) % currentCollection.images.length
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => 
      (prevIndex - 1 + currentCollection.images.length) % currentCollection.images.length
    );
  };

  const selectImage = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <PortfolioWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <SectionTitle
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        Portfolio
      </SectionTitle>
      {collections.reduce((rows, project, index) => {
  if (index % 2 === 0) rows.push([]);
  rows[rows.length - 1].push(project);
  return rows;
}, []).map((row, rowIndex) => (
  <ProjectRow key={rowIndex}>
    {row.map((project, colIndex) => (
      <ProjectColumn
        key={colIndex}
        whileHover="hover"
        onClick={() => openModal(project)}
      >
        {project.title === "ArrDee - Time of My Life" ? (
          <ArrDeeProjectImage src={project.mainImage} alt={project.title} />
        ) : (
          <ProjectImage src={project.mainImage} alt={project.title} />
        )}
        <ProjectOverlay>
          <ProjectType>{project.type}</ProjectType>
          <ProjectTitle>{project.title}</ProjectTitle>
          <ProjectDescription
            variants={{
              hover: { opacity: 1 },
              initial: { opacity: 0 }
            }}
          >
            {project.description}
          </ProjectDescription>
        </ProjectOverlay>
      </ProjectColumn>
    ))}
  </ProjectRow>
))}

      <AnimatePresence>
        {modalOpen && currentCollection && (
          <ModalOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeModal}
          >
            <ModalContent
              onClick={e => e.stopPropagation()}
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <CloseButton
                onClick={closeModal}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <X size={24} />
              </CloseButton>
              <ModalImageContainer>
                <ModalImage 
                  src={currentCollection.images[currentImageIndex]} 
                  alt={currentCollection.title}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  key={currentCollection.images[currentImageIndex]}
                />
                <LeftArrow onClick={prevImage} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                  <ChevronLeft size={24} />
                </LeftArrow>
                <RightArrow onClick={nextImage} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                  <ChevronRight size={24} />
                </RightArrow>
              </ModalImageContainer>
              <ThumbnailContainer>
                {currentCollection.images.map((image, index) => (
                  <Thumbnail 
                    key={index}
                    src={image} 
                    alt={`Thumbnail ${index + 1}`}
                    onClick={() => selectImage(index)}
                    isSelected={index === currentImageIndex}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  />
                ))}
              </ThumbnailContainer>
            </ModalContent>
          </ModalOverlay>
        )}
      </AnimatePresence>
    </PortfolioWrapper>
  );
}

export default Portfolio;