import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';


import aboutImage from '../Images/about.jpeg';

const AboutWrapper = styled(motion.div)`
  max-width: 1200px;
  margin: 0 auto;
  padding: 6rem 2rem;
  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`;

const Title = styled(motion.h1)`
  font-size: 3.5rem;
  margin-bottom: 3rem;
  text-align: center;
  color: #333;
  font-weight: 300;
  letter-spacing: 0.05em;
  @media (max-width: 768px) {
    font-size: 2.8rem;
  }
`;

const ContentWrapper = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4rem;
`;

const ImageSection = styled(motion.div)`
  flex: 1;
  min-width: 300px;
`;

const StyledImage = styled(motion.img)`
  width: 100%;
  height: auto;
  object-fit: cover;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
`;

const TextSection = styled(motion.div)`
  flex: 2;
  min-width: 300px;
`;

const Paragraph = styled(motion.p)`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
  color: #555;
`;

const SkillsSection = styled(motion.div)`
  margin-top: 3rem;
`;

const SkillsTitle = styled(motion.h2)`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
  font-weight: 300;
  letter-spacing: 0.05em;
`;

const SkillsList = styled(motion.ul)`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const SkillItem = styled(motion.li)`
  background-color: #f8f8f8;
  padding: 0.8rem 1.5rem;
  border-radius: 30px;
  font-size: 1rem;
  color: #333;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;

  &:hover {
    background-color: #333;
    color: #fff;
  }
`;

const About = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <AboutWrapper
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <Title variants={itemVariants}>
        About Sinmi Styles
      </Title>
      <ContentWrapper>
        <ImageSection variants={itemVariants}>
          <StyledImage 
            src={aboutImage} 
            alt="Sinmi"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          />
        </ImageSection>
        <TextSection variants={containerVariants}>
          <Paragraph variants={itemVariants}>
            Rachel Onilude, known professionally as Sinmistyles, is no stranger to the creative industry. She began her career in 2020 as a music journalist for the popular platform LinkupTV, where she had the opportunity to interview talents such as Demarco and Aml Ameen. In 2022, Rachel joined the leading talent agency William Morris Endeavor, honing her skills in brand partnerships.
          </Paragraph>
          <Paragraph variants={itemVariants}>
            With a longstanding passion for fashion styling, Rachel officially embarked on her styling journey by launching her page, fitsbyrae, which secured her first client just two weeks later. Since then, she has been fortunate to lead and assist on a range of exciting projects.
          </Paragraph>
          <Paragraph variants={itemVariants}>
            A significant highlight of her career was serving as an assistant stylist for Omah Lay in the Deeds Magazine "Culture Unwrapped" issue in December 2023. Nine months later, she took on the role of lead stylist for Tanzanian artist Rayvanny in another editorial for Deeds Magazine.
          </Paragraph>
          <Paragraph variants={itemVariants}>
            Sinmistyles' portfolio also includes styling presenter Jourds for her Channel 4 hosting of Blue Therapy, working with rapper Potter Payper's producer GX for the 2024 MOBO Awards, and assisting stylist ArrDee for the Time of My Life music video featuring renowned Afrobeat artist Phyno.
          </Paragraph>
          <Paragraph variants={itemVariants}>
            Rachel specialises in fashion styling and brand partnerships, leveraging her expertise to help individuals and companies bring their visions to life for editorial shoots, red-carpet events, and music videos. Her aim is to create unforgettable styles that leave a lasting impression.
          </Paragraph>
          <SkillsSection variants={containerVariants}>
            <SkillsTitle variants={itemVariants}>Expertise</SkillsTitle>
            <SkillsList>
              {['Fashion Styling', 'Music Video Styling','Brand Partnerships','Event Styling', 'Editorial Styling', 'Personal Styling'].map((skill, index) => (
                <SkillItem 
                  key={index} 
                  variants={itemVariants}
                  whileHover={{ scale: 1.05, boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)' }}
                  whileTap={{ scale: 0.95 }}
                >
                  {skill}
                </SkillItem>
              ))}
            </SkillsList>
          </SkillsSection>
        </TextSection>
      </ContentWrapper>
    </AboutWrapper>
  );
};

export default About;