const theme = {
    colors: {
      background: '#ffffff',
      text: '#333333',
      primary: '#000000',
      secondary: '#ffffff',
      accent: '#ff4081',
    },
    breakpoints: {
      mobile: '480px',
      tablet: '768px',
      desktop: '1024px',
    },
  };
  
  export default theme;