import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X } from 'lucide-react';


const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: ${props => props.isHome ? 'transparent' : '#fff'};
  position: ${props => props.isHome ? 'absolute' : 'relative'};
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
`;

const Logo = styled(Link)`
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.2em;
  text-decoration: none;
  color: ${props => props.isHome ? '#fff' : props.theme.colors.primary};
  font-family: 'Playfair Display', serif;
`;

const Nav = styled(motion.nav)`
  @media (max-width: 768px) {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: ${props => props.isHome ? 'rgba(0, 0, 0, 0.8)' : '#fff'};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
`;

const NavList = styled.ul`
  display: flex;
  list-style-type: none;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

const NavItem = styled(motion.li)`
  a {
    text-decoration: none;
    color: ${props => props.isHome ? '#fff' : props.theme.colors.text};
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    position: relative;
    font-family: 'Playfair Display', serif;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 1px;
      bottom: -5px;
      left: 0;
      background-color: ${props => props.isHome ? '#fff' : props.theme.colors.accent};
      transition: width 0.3s ease;
    }

    &:hover::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const HamburgerButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.isHome ? '#fff' : props.theme.colors.text};

  @media (max-width: 768px) {
    display: block;
  }
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();
  const isHome = location.pathname === '/';

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menuVariants = {
    closed: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    },
    open: {
      height: "auto",
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    }
  };

  return (
    <HeaderWrapper isHome={isHome}>
      <Logo to="/" isHome={isHome}>SINMI STYLES</Logo>
      {isMobile && (
        <HamburgerButton onClick={toggleMenu} isHome={isHome}>
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </HamburgerButton>
      )}
      <AnimatePresence>
        {(!isMobile || isOpen) && (
          <Nav
            initial={isMobile ? "closed" : false}
            animate={isMobile ? "open" : false}
            exit="closed"
            variants={menuVariants}
            isHome={isHome}
          >
            <NavList>
              {['Home', 'Portfolio', 'About', 'Contact'].map((item) => (
                <NavItem
                  key={item}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  isHome={isHome}
                >
                  <Link to={item === 'Home' ? '/' : `/${item.toLowerCase()}`} onClick={() => isMobile && setIsOpen(false)}>
                    {item}
                  </Link>
                </NavItem>
              ))}
            </NavList>
          </Nav>
        )}
      </AnimatePresence>
    </HeaderWrapper>
  );
};

export default Header;