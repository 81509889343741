import React from 'react';
import styled from 'styled-components';
import { FaInstagram, FaEnvelope } from 'react-icons/fa';
import { motion } from 'framer-motion';

const FooterWrapper = styled.footer`
  background-color: #f8f8f8;
  color: #333;
  padding: 3rem 0;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const BrandName = styled.h2`
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
`;

const SocialLink = styled(motion.a)`
  color: #333;
  font-size: 1.2rem;
  transition: color 0.3s ease;

  &:hover {
    color: #ff4081;
  }
`;

const Copyright = styled.p`
  font-size: 0.9rem;
  margin: 0;
`;

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <FooterWrapper>
      <FooterContent>
        <BrandName>SINMISTYLES</BrandName>
        <SocialLinks>
          <SocialLink 
            href="https://www.instagram.com/sinmistyles_/" 
            target="_blank" 
            rel="noopener noreferrer"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaInstagram />
          </SocialLink>
          <SocialLink 
            href="mailto:racheloniludework@gmail.com"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaEnvelope />
          </SocialLink>
        </SocialLinks>
        <Copyright>© {currentYear} SINMISTYLES. ALL RIGHTS RESERVED.</Copyright>
      </FooterContent>
    </FooterWrapper>
  );
}

export default Footer;