import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaInstagram, FaEnvelope } from 'react-icons/fa';

// Styled components for the contact form

// Main wrapper for the contact form
const ContactWrapper = styled(motion.div)`
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem 1rem;
  
  @media (min-width: 768px) {
    max-width: 800px;
    padding: 4rem 2rem;
  }
`;

// Title styling
const Title = styled(motion.h1)`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 300;
  letter-spacing: 0.05em;
  color: #333;
  
  @media (min-width: 768px) {
    font-size: 3.5rem;
    margin-bottom: 3rem;
  }
`;

// Contact information container
const ContactInfo = styled(motion.div)`
  text-align: center;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 3rem;
  }
`;

// Styling for contact links (Instagram and Email)
const ContactLink = styled(motion.a)`
  display: flex;
  align-items: center;
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;

  &:hover {
    color: #ff4081;
  }

  svg {
    margin-right: 0.5rem;
    font-size: 1.2rem;
  }
  
  @media (min-width: 768px) {
    font-size: 1.1rem;
    
    svg {
      font-size: 1.3rem;
    }
  }
`;

// Styling for the quote message
const QuoteMessage = styled(motion.p)`
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 2rem;
  color: #555;
  line-height: 1.6;
  
  @media (min-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 3rem;
  }
`;

// Styling for the form
const StyledForm = styled(motion.form)`
  display: flex;
  flex-direction: column;
`;

// Wrapper for input fields
const InputWrapper = styled(motion.div)`
  margin-bottom: 1rem;
  
  @media (min-width: 768px) {
    margin-bottom: 1.5rem;
  }
`;

// Styling for input fields
const StyledInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #ff4081;
  }
  
  @media (min-width: 768px) {
    padding: 1rem;
  }
`;

// Styling for textarea
const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 120px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #ff4081;
  }
  
  @media (min-width: 768px) {
    padding: 1rem;
    min-height: 150px;
  }
`;

// Styling for the submit button
const StyledButton = styled(motion.button)`
  padding: 0.75rem 1.5rem;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff4081;
  }
  
  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

// Styling for error messages
const ErrorMessage = styled(motion.span)`
  color: #ff4081;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  display: block;
`;

// Styling for select dropdown
const StyledSelect = styled.select`
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  background-color: white;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 0.75rem top 50%;
  background-size: 0.65rem auto;

  &:focus {
    outline: none;
    border-color: #ff4081;
  }
  
  @media (min-width: 768px) {
    padding: 1rem;
    background-position: right 1rem top 50%;
  }
`;

// Main Contact component
function Contact() {
  // State for form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    category: '',
    message: ''
  });

  // State for form errors
  const [errors, setErrors] = useState({});

  // State to track if the form has been submitted
  const [submitted, setSubmitted] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  // Validate form inputs
  const validateForm = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Name is required";
    if (!formData.email) {
      tempErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email is invalid";
    }
    if(!formData.category) tempErrors.category = "Category is required";
    if (!formData.message) tempErrors.message = "Message is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const form = e.target;
      // Submit form data to Netlify
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(new FormData(form)).toString()
      })
        .then(() => {
          setSubmitted(true);
          setFormData({ name: '', email: '', category: '', message: '' });
        })
        .catch((error) => alert(error));
    }
  };

  // Animation variants for Framer Motion
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <ContactWrapper
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      {/* Title */}
      <Title variants={itemVariants}>Contact Us</Title>

      {/* Contact Information */}
      <ContactInfo variants={itemVariants}>
        <ContactLink 
          href="https://www.instagram.com/sinmistyles_/" 
          target="_blank" 
          rel="noopener noreferrer"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <FaInstagram /> @sinmistyles_
        </ContactLink>
        <ContactLink 
          href="mailto:racheloniludework@gmail.com"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <FaEnvelope /> racheloniludework@gmail.com
        </ContactLink>
      </ContactInfo>

      {/* Quote Message */}
      <QuoteMessage variants={itemVariants}>
        Contact me to get a quote on how much our services will cost for your specific needs.
      </QuoteMessage>

      {/* Contact Form */}
      <StyledForm 
        onSubmit={handleSubmit} 
        variants={containerVariants}
        name="contact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        {/* Hidden inputs for Netlify form handling */}
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <input name="bot-field" />
        </div>

        {/* Name Input */}
        <InputWrapper variants={itemVariants}>
          <StyledInput 
            name="name" 
            placeholder="Name" 
            value={formData.name}
            onChange={handleChange}
          />
          <AnimatePresence>
            {errors.name && (
              <ErrorMessage
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
              >
                {errors.name}
              </ErrorMessage>
            )}
          </AnimatePresence>
        </InputWrapper>
        
        {/* Email Input */}
        <InputWrapper variants={itemVariants}>
          <StyledInput 
            name="email" 
            placeholder="Email" 
            value={formData.email}
            onChange={handleChange}
          />
          <AnimatePresence>
            {errors.email && (
              <ErrorMessage
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
              >
                {errors.email}
              </ErrorMessage>
            )}
          </AnimatePresence>
        </InputWrapper>

        {/* Category Select */}
        <InputWrapper variants={itemVariants}>
          <StyledSelect
            name="category"
            value={formData.category}
            onChange={handleChange}
          >
            <option value="">Select a category</option>
            <option value="events">Events</option>
            <option value="editorials">Editorials</option>
            <option value="videos">Videos</option>
            <option value="other">Other</option>
          </StyledSelect>
          <AnimatePresence>
            {errors.category && (
              <ErrorMessage
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
              >
                {errors.category}
              </ErrorMessage>
            )}
          </AnimatePresence>
        </InputWrapper>
        
        {/* Message Textarea */}
        <InputWrapper variants={itemVariants}>
          <StyledTextArea 
            name="message" 
            placeholder="Message" 
            value={formData.message}
            onChange={handleChange}
          />
          <AnimatePresence>
            {errors.message && (
              <ErrorMessage
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
              >
                {errors.message}
              </ErrorMessage>
            )}
          </AnimatePresence>
        </InputWrapper>
        
        {/* Submit Button */}
        <StyledButton 
          type="submit"
          variants={itemVariants}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Send
        </StyledButton>
      </StyledForm>

      {/* Submission Confirmation Message */}
      {submitted && (
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          Thank you for your message. We'll get back to you soon!
        </motion.p>
      )}
    </ContactWrapper>
  );
}

export default Contact;