import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion} from 'framer-motion';
import { Link } from 'react-router-dom';

import editorial1 from '../Images/editorial.jpeg';
import editorial3 from '../Images/editorial3.jpeg';
import editorial8 from '../Images/editorial8.jpeg';
import editorial9 from '../Images/editorial9.jpeg';
import events1 from '../Images/events1.jpeg';
import event6 from '../Images/events6.jpeg';
import musicVideo17 from '../Images/musicVideo17.jpeg';


const HomeWrapper = styled.div`
  min-height: 100vh;
  font-family: 'Playfair Display', serif;
`;

const HeroSection = styled(motion.section)`
  height: 100vh;
  min-height: 800px; 
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;

  @media (min-width: 1024px) {
    height: 120vh; // Increase height for desktop screens
    min-height: 1000px; // Ensure a tall minimum height
  }

  @media (min-width: 1440px) {
    height: 130vh; // Even taller for very large screens
    min-height: 1200px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4); // Semi-transparent overlay
    z-index: 1;
  }
`;

const HeroContent = styled(motion.div)`
  text-align: center;
  color: #fff;
  z-index: 2;
  position: relative;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  width: 100%;

  @media (min-width: 1024px) {
    max-width: 60%;
  }
`;

const Title = styled(motion.h1)`
  font-size: 4rem;
  margin-bottom: 1rem;
  font-weight: 300;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  @media (min-width: 1024px) {
    font-size: 5rem;
  }

  @media (min-width: 1440px) {
    font-size: 6rem;
  }
`;

const Subtitle = styled(motion.p)`
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.05em;
  max-width: 600px;
  margin: 0 auto;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); 
  @media (min-width: 1024px) {
    font-size: 1.8rem;
    max-width: 800px;
  }
`;

const SectionTitle = styled(motion.h2)`
  font-size: 3rem;
  margin-bottom: 3rem;
  font-weight: 300;
  text-align: center;
  letter-spacing: 0.05em;
`;

const Section = styled(motion.section)`
  padding: 8rem 2rem;
  background-color: #f9f9f9;
  text-align: center;
`;



const WorksGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  margin-top: 3rem;
`;

const WorkItem = styled(motion.div)`
  position: relative;
  height: 500px;
  overflow: hidden;
`;

const WorkImage = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const WorkOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const OverlayTitle = styled.span`
  color: #fff;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  letter-spacing: 0.05em;
`;

const OverlayDescription = styled.span`
  color: #fff;
  font-size: 1rem;
  max-width: 80%;
  text-align: center;
`;

const CTAButton = styled(motion(Link))`
  display: inline-block;
  margin-top: 3rem;
  padding: 1rem 2rem;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #333;
  }
`;

const TestimonialGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const TestimonialCard = styled(motion.div)`
  background-color: #f8f8f8;
  padding: 2rem;
`;

const TestimonialText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  font-style: italic;
`;



const StyleWord = styled(motion.span)`
  display: inline-block;
  font-weight: 700;
  color: #ff4081;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); 
`;

const Home = () => {
    // State for managing current image and word
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

   // Arrays for rotating images and style words
  const editorialImages = [
    editorial1, editorial3,  
    editorial8, editorial9, events1, event6
  ];
  const styleWords = ['Bold', 'Elegant', 'Timeless', 'Unique', 'Iconic'];

  useEffect(() => {
     // Set up interval for changing background image
    const imageInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % editorialImages.length);
    }, 5000);
 // Set up interval for changing style word
    const wordInterval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % styleWords.length);
    }, 2000);

    return () => {
      clearInterval(imageInterval);
      clearInterval(wordInterval);
    };
  }, [editorialImages.length, styleWords.length]);

  const featuredWorks = [
    { id: 1, title: 'Editorial Shoot',  image: editorial1 },
    { id: 2, title: 'Event Styling',  image: events1 },
    { id: 3, title: 'Music Video Styling', image: musicVideo17 },
  ];

  const testimonials = [
    {
      text: "This is first time I've worked with Rachel and it has been truly a fantastic experience. She studied my style like a fashion detective and chose clothes that feel just my style. After meeting with Rachel for the first time, I immediately knew that she understood what I was looking to shop for.",
    },
    {
      text: "Rachel was super helpful and made Demi feel comfortable. Great day honestly. Rachel is a gem💎",
    }
  ];

  return (
    <HomeWrapper>
      <HeroSection
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{ backgroundImage: `url(${editorialImages[currentImageIndex]})` }}
      >
        <HeroContent>
          <Title
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Craft your style, shape your vision
          </Title>
          <Subtitle
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            Bringing your vision to life through <StyleWord key={styleWords[currentWordIndex]}>{styleWords[currentWordIndex]}</StyleWord> style
          </Subtitle>
        </HeroContent>
      </HeroSection>

      <Section>
        <SectionTitle
          initial={{ y: 20, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          Featured Works
        </SectionTitle>
        <WorksGrid
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8, staggerChildren: 0.2 }}
          viewport={{ once: true }}
        >
          {featuredWorks.map((work) => (
            <WorkItem
              key={work.id}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <WorkImage src={work.image} alt={work.title} />
              <WorkOverlay
                initial={{ opacity: 0 }}
                whileHover={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                <OverlayTitle>{work.title}</OverlayTitle>
                <OverlayDescription>{work.description}</OverlayDescription>
              </WorkOverlay>
            </WorkItem>
          ))}
        </WorksGrid>
        <CTAButton
          to="/portfolio"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Explore Full Portfolio
        </CTAButton>
      </Section>

      <Section style={{ backgroundColor: '#f8f8f8' }}>
        <SectionTitle
          initial={{ y: 20, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          What Clients Say
        </SectionTitle>
        <TestimonialGrid
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8, staggerChildren: 0.2 }}
          viewport={{ once: true }}
        >
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              initial={{ y: 20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: index * 0.2, duration: 0.8 }}
              viewport={{ once: true }}
            >
              <TestimonialText>{testimonial.text}</TestimonialText>
             
            </TestimonialCard>
          ))}
        </TestimonialGrid>
      </Section>
    </HomeWrapper>
  );
};

export default Home;